import React, { useEffect, useState } from "react";

import PdfIcons from "../../assets/images/svg/pdf-icon.svg";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Company, IBilan, IS3, User } from "../../interfaces";
import FileViewer from "../../components/FileViewer";
import LetterMission from "../ClientInfo/LetterMission";
import { GedDocTypes, OptionType } from "../../util/context";
import OtherDocs from "../../components/OtherDocs";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import { fetchAllCompanies } from "../../store/reducers/companies-list/companiesSlice";
import moment from "moment";
moment.updateLocale("fr", {});

export interface GestionDocProps {}

type CustomCompany = Omit<
  Partial<Company>,
  "listOfBeneficialOwners" | "kbis" | "status" | "listOfBeneficialOwners"
> & {
  listOfBeneficialOwners: IS3[] | null;
  kbis: IS3 | null;
  status: IS3 | null;
  // listOfBeneficialOwners: IS3 | null;
};

const ArchivedBilan = ({
  context,
  company,
  client,
  callback,
  bilanYear,
  oldBilans,
}: {
  context?: string;
  company?: CustomCompany | Company;
  client?: User;
  callback?: () => void;
  bilanYear: OptionType;
  oldBilans: IBilan[];
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [lettreModal, setLettreModal] = useState<boolean>(false);
  const [activeBilan, setActiveBilan] = useState<IBilan | null>();
  const [reload, setReload] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  
  let api = useAxios();
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveBilan(
      oldBilans.find((elt) => elt.year === bilanYear.value)
        ? oldBilans.find((elt) => elt.year === bilanYear.value)
        : null
    );
  }, [bilanYear]);

  useEffect(() => {
    if (reload) {
      dispatch(fetchAllCompanies());
      setReload(false);
    }
  }, [reload]);

  return (
    <>
      <div className="container">
        <div className="section-information-societe">
          {!activeBilan ||
          ((!activeBilan.relevesBancaires ||
            (activeBilan.relevesBancaires &&
              activeBilan.relevesBancaires.length === 0)) &&
            (!activeBilan.emprunt ||
              (activeBilan.emprunt && activeBilan.emprunt.length === 0)) &&
            (!activeBilan.cotisationsObligatoires ||
              (activeBilan.cotisationsObligatoires &&
                activeBilan.cotisationsObligatoires.length === 0)) &&
            (!activeBilan.cotisationsFacultatives ||
              (activeBilan.cotisationsFacultatives &&
                activeBilan.cotisationsFacultatives.length === 0)) &&
            (!activeBilan.documentsFiscaux ||
              (activeBilan.documentsFiscaux &&
                activeBilan.documentsFiscaux.length === 0)) &&
            (!activeBilan.documentsSupplementaires ||
              (activeBilan.documentsSupplementaires &&
                activeBilan.documentsSupplementaires.length === 0)) &&
            !activeBilan.liasseFiscale &&
            !activeBilan.FEC &&
            !activeBilan.recapIR &&
            !activeBilan.fullRecap) ? (
            <Row>
              <h3>Bilan {bilanYear.label}</h3>
              <Col md={12} className="px-lg-4 text-center">
                <div>Pas de bilan pour l'année {bilanYear.label}</div>
              </Col>
            </Row>
          ) : (
            <Row>
              <h3>Bilan {bilanYear.label}</h3>
              {activeBilan &&
                activeBilan.relevesBancaires &&
                activeBilan.relevesBancaires.length > 0 && (
                  <Col md={3} sm={12} className="px-lg-4">
                    <Label>Relevés bancaires</Label>
                    {activeBilan?.relevesBancaires?.map((elt, index) => (
                      <div
                        className="document-item"
                        key={index}
                        onClick={() => {
                          setUrl(elt.url);
                          setViewModal(true);
                        }}
                      >
                        <span className="doc-icon">
                          <img src={PdfIcons} alt="icon" />
                        </span>
                        <span className="doc-name">{elt.key}</span>
                      </div>
                    ))}
                  </Col>
                )}

              {activeBilan &&
                activeBilan.emprunt &&
                activeBilan.emprunt.length > 0 && (
                  <Col md={3} sm={12} className="px-lg-4">
                    <Label>Emprunt</Label>
                    {activeBilan?.emprunt?.map((elt, index) => (
                      <div
                        className="document-item"
                        key={index}
                        onClick={() => {
                          setUrl(elt.url);
                          setViewModal(true);
                        }}
                      >
                        <span className="doc-icon">
                          <img src={PdfIcons} alt="icon" />
                        </span>
                        <span className="doc-name">{elt.key}</span>
                      </div>
                    ))}
                  </Col>
                )}

              {activeBilan &&
                activeBilan.cotisationsObligatoires &&
                activeBilan.cotisationsObligatoires.length > 0 && (
                  <Col md={3} sm={12} className="ps-lg-4">
                    <Label>Cotisations sociales obligatoires</Label>
                    {activeBilan?.cotisationsObligatoires?.map((elt, index) => (
                      <div
                        className="document-item"
                        key={index}
                        onClick={() => {
                          setUrl(elt.url);
                          setViewModal(true);
                        }}
                      >
                        <span className="doc-icon">
                          <img src={PdfIcons} alt="icon" />
                        </span>
                        <span className="doc-name">{elt.key}</span>
                      </div>
                    ))}
                  </Col>
                )}

              {activeBilan &&
                activeBilan.cotisationsFacultatives &&
                activeBilan.cotisationsFacultatives.length > 0 && (
                  <Col md={3} sm={12} className="ps-lg-4">
                    <Label>Cotisations sociales facultatives</Label>
                    {activeBilan?.cotisationsFacultatives?.map((elt, index) => (
                      <div
                        className="document-item"
                        key={index}
                        onClick={() => {
                          setUrl(elt.url);
                          setViewModal(true);
                        }}
                      >
                        <span className="doc-icon">
                          <img src={PdfIcons} alt="icon" />
                        </span>
                        <span className="doc-name">{elt.key}</span>
                      </div>
                    ))}
                  </Col>
                )}

              {activeBilan &&
                activeBilan.documentsFiscaux &&
                activeBilan.documentsFiscaux.length > 0 && (
                  <Col md={3} sm={12} className="ps-lg-4">
                    <Label>Documents fiscaux</Label>
                    {activeBilan?.documentsFiscaux?.map((elt, index) => (
                      <div
                        className="document-item"
                        key={index}
                        onClick={() => {
                          setUrl(elt.url);
                          setViewModal(true);
                        }}
                      >
                        <span className="doc-icon">
                          <img src={PdfIcons} alt="icon" />
                        </span>
                        <span className="doc-name">{elt.key}</span>
                      </div>
                    ))}
                  </Col>
                )}

              {activeBilan &&
                activeBilan.documentsSupplementaires &&
                activeBilan.documentsSupplementaires.length > 0 && (
                  <Col md={3} sm={12} className="ps-lg-4">
                    <Label>Documents supplémentaires</Label>
                    {activeBilan?.documentsSupplementaires?.map(
                      (elt, index) => (
                        <div
                          className="document-item"
                          key={index}
                          onClick={() => {
                            setUrl(elt.url);
                            setViewModal(true);
                          }}
                        >
                          <span className="doc-icon">
                            <img src={PdfIcons} alt="icon" />
                          </span>
                          <span className="doc-name">{elt.key}</span>
                        </div>
                      )
                    )}
                  </Col>
                )}

              {activeBilan && activeBilan.liasseFiscale && (
                <Col md={3} sm={12} className="ps-lg-4">
                  <Label>Liasse fiscale</Label>

                  <div
                    className="document-item"
                    onClick={() => {
                      setUrl(activeBilan.liasseFiscale.url);
                      setViewModal(true);
                    }}
                  >
                    <span className="doc-icon">
                      <img src={PdfIcons} alt="icon" />
                    </span>
                    <span className="doc-name">
                      {activeBilan.liasseFiscale.key}
                    </span>
                  </div>
                </Col>
              )}

              {activeBilan && activeBilan.FEC && (
                <Col md={3} sm={12} className="ps-lg-4">
                  <Label>FEC</Label>

                  <div
                    className="document-item"
                    onClick={() => {
                      setUrl(activeBilan.FEC.url);
                      setViewModal(true);
                    }}
                  >
                    <span className="doc-icon">
                      <img src={PdfIcons} alt="icon" />
                    </span>
                    <span className="doc-name">{activeBilan.FEC.key}</span>
                  </div>
                </Col>
              )}

              {activeBilan && activeBilan.recapIR && (
                <Col md={3} sm={12} className="ps-lg-4">
                  <Label>Aide pour impôt sur le revenu</Label>

                  <div
                    className="document-item"
                    onClick={() => {
                      setUrl(activeBilan.recapIR.url);
                      setViewModal(true);
                    }}
                  >
                    <span className="doc-icon">
                      <img src={PdfIcons} alt="icon" />
                    </span>
                    <span className="doc-name">{activeBilan.recapIR.key}</span>
                  </div>
                </Col>
              )}

              {activeBilan && activeBilan.fullRecap && (
                <Col md={3} sm={12} className="ps-lg-4">
                  <Label>Récapitulatif Bilan</Label>

                  <div
                    className="document-item"
                    onClick={() => {
                      setUrl(activeBilan.fullRecap.url);
                      setViewModal(true);
                    }}
                  >
                    <span className="doc-icon">
                      <img src={PdfIcons} alt="icon" />
                    </span>
                    <span className="doc-name">
                      {activeBilan.fullRecap.key}
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          )}
          <Row className="mt-lg-5">
            <OtherDocs
              modelId={company?.id!}
              data={
                company?.ged_docs && company.ged_docs.length > 0
                  ? company.ged_docs
                  : []
              }
              setRelaod={setReload}
              token={creds.token}
              api={api}
              additionalData={{
                type: GedDocTypes.Bilan,
                year: bilanYear.value || moment().get("year"),
              }}
            />
          </Row>
        </div>
      </div>
      <div className="openbtn text-center">
        <Modal
          isOpen={lettreModal}
          toggle={() => {
            setLettreModal(false);
          }}
          className="modal-secondary modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setLettreModal(false);
            }}
          >
            Vérification de la catégorisation
          </ModalHeader>
          <ModalBody>
            <LetterMission
              companyName={company && (company.name || "")}
              companyAddress={company && (company.headOffice || "")}
              companyForm={company && (company.legalForm || "")}
              companyTVA={company && company.subjectToVAT ? "" : "non"}
              userName={
                client && `${client.firstName ?? ""} ${client.lastName ?? ""}`
              }
            />
          </ModalBody>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default ArchivedBilan;
